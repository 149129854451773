import React, { useState, lazy, Suspense } from 'react';
import { motion } from 'framer-motion';

// Import components that are always needed
import Header from './Header';
import Footer from './Footer';
import RegistrationOptions from './RegistrationOptions';

// Lazy load components that are not immediately necessary
const InstructorInfo = lazy(() => import('./InstructorInfo'));
const LocationMap = lazy(() => 
  import('./LocationMap').then(module => ({
    default: props => <Suspense fallback={<div>Loading map...</div>}><module.default {...props} /></Suspense>
  }))
);
const WaitingListFormModal = lazy(() => import('./WaitingListFormModal'));

const LandingPage = () => {
  const [showWaitingListFormModal, setShowWaitingListFormModal] = useState(false);

  const handleSubmit = async (formData) => {
    try {
      const response = await fetch('https://formspree.io/f/meojwjzw', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to submit form');
      }
    } catch (error) {
      console.error('Form submission error:', error);
      throw error;
    }
  };

  return (
    <div className="flex flex-col min-h-screen text-black bg-landing-pattern bg-cover bg-center bg-fixed bg-no-repeat">
      <Header />
      <main className="relative z-10 flex-grow mt-16">
        <div className="container mx-auto px-4 py-4 sm:py-8">
          <motion.div 
            className="flex flex-col items-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <img 
              src="/images/galway-school-logo.png" 
              alt="Galway School of Irish Dancing" 
              className="w-32 h-32 sm:w-48 sm:h-48 rounded-full border-4 border-black mb-6 sm:mb-8 shadow-lg"
              loading="lazy"
            />
            <h2 className="text-3xl sm:text-4xl font-extrabold mb-4 sm:mb-6 text-gold drop-shadow-lg text-center">Galway School of Irish Dancing</h2>
            
            <p className="text-xl sm:text-2xl md:text-3xl mb-8 sm:mb-10 text-center max-w-2xl leading-relaxed font-serif italic text-shadow-custom-blue">
              Step into tradition, dance into the future.
            </p>
            
            <RegistrationOptions 
              onJoinWaitingList={() => setShowWaitingListFormModal(true)}
            />

            <div className="grid md:grid-cols-2 gap-8 mt-12 w-full">
              <Suspense fallback={<div>Loading...</div>}>
                <InstructorInfo />
                <LocationMap />
              </Suspense>
            </div>
          </motion.div>
        </div>
      </main>

      <Footer />

      <Suspense fallback={<div>Loading...</div>}>
        {showWaitingListFormModal && (
          <WaitingListFormModal
            isOpen={showWaitingListFormModal}
            onClose={() => setShowWaitingListFormModal(false)}
            onSubmit={handleSubmit}
          />
        )}
      </Suspense>
    </div>
  );
};

export default LandingPage;