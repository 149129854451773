import React from 'react';
import { motion } from 'framer-motion';

const RegistrationOptions = ({ onJoinWaitingList }) => (
  <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4 mb-8">
    <motion.button
      onClick={onJoinWaitingList}
      className="bg-gold text-black font-bold py-3 px-6 rounded-md hover:bg-light-gold transition duration-300 shadow-lg"
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      Join Waiting List
    </motion.button>
  </div>
);

export default RegistrationOptions;