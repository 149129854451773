import React from 'react';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';

const Footer = () => (
  <footer className="relative z-10 bg-white py-4">
    <div className="container mx-auto px-4 text-center">
      <div className="flex justify-center space-x-8 mb-4">
        <a href="https://www.facebook.com/profile.php?id=61563335731693" target="_blank" rel="noopener noreferrer" className="text-gold hover:text-light-gold transition duration-300 transform hover:scale-110">
          <FaFacebookF size={24} />
        </a>
        <a href="https://www.instagram.com/galwayschoolofirishdance/" target="_blank" rel="noopener noreferrer" className="text-gold hover:text-light-gold transition duration-300 transform hover:scale-110">
          <FaInstagram size={24} />
        </a>
      </div>
    </div>
  </footer>
);

export default Footer;