import React from 'react';

const Header = () => (
  <header className="fixed top-0 left-0 right-0 z-50 bg-white bg-opacity-80 backdrop-filter backdrop-blur-lg shadow-md">
    <div className="container mx-auto px-0 sm:px-4 py-2 md:py-1">
      <div className="flex justify-between items-center">
        <div className="pl-4 sm:pl-0">
          <img 
            src="/images/galway-irish-dance-landing.png" 
            alt="Galway School of Irish Dancing" 
            className="h-12 md:h-16 lg:h-15 w-auto object-contain"
          />
        </div>
        <nav className="hidden md:block pr-4 sm:pr-0">
          {/* Add navigation items here in the future */}
        </nav>
      </div>
    </div>
  </header>
);

export default Header;